import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _593f7f51 = () => interopDefault(import('../pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))
const _ff4f3d2c = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _22c0d7e5 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _8f8f8b18 = () => interopDefault(import('../pages/elearning/index.vue' /* webpackChunkName: "pages/elearning/index" */))
const _4c156ead = () => interopDefault(import('../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _913d2238 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _783be9a0 = () => interopDefault(import('../pages/indexNew.vue' /* webpackChunkName: "pages/indexNew" */))
const _03ae7437 = () => interopDefault(import('../pages/indexOld.vue' /* webpackChunkName: "pages/indexOld" */))
const _8a0be4bc = () => interopDefault(import('../pages/instructor/index.vue' /* webpackChunkName: "pages/instructor/index" */))
const _761b35cc = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _3cdeecda = () => interopDefault(import('../pages/streaming/index.vue' /* webpackChunkName: "pages/streaming/index" */))
const _6621e34a = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _42e3f697 = () => interopDefault(import('../pages/collection/elearning/index.vue' /* webpackChunkName: "pages/collection/elearning/index" */))
const _6c3c45b6 = () => interopDefault(import('../pages/collection/streaming/index.vue' /* webpackChunkName: "pages/collection/streaming/index" */))
const _33adc95f = () => interopDefault(import('../pages/dashboard/profile/index.vue' /* webpackChunkName: "pages/dashboard/profile/index" */))
const _2bfc6ed4 = () => interopDefault(import('../pages/dashboard/transaction/index.vue' /* webpackChunkName: "pages/dashboard/transaction/index" */))
const _72c6c29e = () => interopDefault(import('../pages/elearning/special/index.vue' /* webpackChunkName: "pages/elearning/special/index" */))
const _1a04455a = () => interopDefault(import('../pages/home/indexOld.vue' /* webpackChunkName: "pages/home/indexOld" */))
const _e0b92736 = () => interopDefault(import('../pages/site/aboutus.vue' /* webpackChunkName: "pages/site/aboutus" */))
const _4b31e238 = () => interopDefault(import('../pages/site/contactus.vue' /* webpackChunkName: "pages/site/contactus" */))
const _87ac2fe0 = () => interopDefault(import('../pages/site/faq.vue' /* webpackChunkName: "pages/site/faq" */))
const _706e7682 = () => interopDefault(import('../pages/site/privacy.vue' /* webpackChunkName: "pages/site/privacy" */))
const _1adfdf78 = () => interopDefault(import('../pages/streaming/indexOld.vue' /* webpackChunkName: "pages/streaming/indexOld" */))
const _6ac25228 = () => interopDefault(import('../pages/streaming/indexOldNew.vue' /* webpackChunkName: "pages/streaming/indexOldNew" */))
const _52acfa86 = () => interopDefault(import('../pages/streaming/special/index.vue' /* webpackChunkName: "pages/streaming/special/index" */))
const _2fe5205e = () => interopDefault(import('../pages/collection/elearning/idOld.vue' /* webpackChunkName: "pages/collection/elearning/idOld" */))
const _1165bef0 = () => interopDefault(import('../pages/collection/streaming/idOld.vue' /* webpackChunkName: "pages/collection/streaming/idOld" */))
const _0f1098c0 = () => interopDefault(import('../pages/collection/streaming/idOldNew.vue' /* webpackChunkName: "pages/collection/streaming/idOldNew" */))
const _c7aca620 = () => interopDefault(import('../pages/collection/streaming/idOldNewNew.vue' /* webpackChunkName: "pages/collection/streaming/idOldNewNew" */))
const _8ff0a662 = () => interopDefault(import('../pages/collection/streaming/indexOldNew.vue' /* webpackChunkName: "pages/collection/streaming/indexOldNew" */))
const _775cb2bf = () => interopDefault(import('../pages/collection/elearning/_id.vue' /* webpackChunkName: "pages/collection/elearning/_id" */))
const _67a242de = () => interopDefault(import('../pages/collection/elearning/_idNew.vue' /* webpackChunkName: "pages/collection/elearning/_idNew" */))
const _266c3d9e = () => interopDefault(import('../pages/collection/streaming/_id.vue' /* webpackChunkName: "pages/collection/streaming/_id" */))
const _92fccda8 = () => interopDefault(import('../pages/elearning/_slug.vue' /* webpackChunkName: "pages/elearning/_slug" */))
const _196c75f8 = () => interopDefault(import('../pages/elearning/_slugNew.vue' /* webpackChunkName: "pages/elearning/_slugNew" */))
const _356fea2c = () => interopDefault(import('../pages/elearning/_slugNewNew.vue' /* webpackChunkName: "pages/elearning/_slugNewNew" */))
const _33162e0b = () => interopDefault(import('../pages/elearning/_slugOld.vue' /* webpackChunkName: "pages/elearning/_slugOld" */))
const _29b53d31 = () => interopDefault(import('../pages/form/_id.vue' /* webpackChunkName: "pages/form/_id" */))
const _5467726f = () => interopDefault(import('../pages/payment/_slug.vue' /* webpackChunkName: "pages/payment/_slug" */))
const _1b2623f8 = () => interopDefault(import('../pages/reset/_slug.vue' /* webpackChunkName: "pages/reset/_slug" */))
const _404c2f6a = () => interopDefault(import('../pages/streaming/_slug.vue' /* webpackChunkName: "pages/streaming/_slug" */))
const _70f2260b = () => interopDefault(import('../pages/streaming/_slugNewNew.vue' /* webpackChunkName: "pages/streaming/_slugNewNew" */))
const _e294d2e8 = () => interopDefault(import('../pages/streaming/_slugOld.vue' /* webpackChunkName: "pages/streaming/_slugOld" */))
const _853768b8 = () => interopDefault(import('../pages/streaming/_slugOldNew.vue' /* webpackChunkName: "pages/streaming/_slugOldNew" */))
const _63f909d8 = () => interopDefault(import('../pages/verification/_slug.vue' /* webpackChunkName: "pages/verification/_slug" */))
const _061ee280 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agenda",
    component: _593f7f51,
    name: "agenda"
  }, {
    path: "/auth",
    component: _ff4f3d2c,
    name: "auth"
  }, {
    path: "/cart",
    component: _22c0d7e5,
    name: "cart"
  }, {
    path: "/elearning",
    component: _8f8f8b18,
    name: "elearning"
  }, {
    path: "/guide",
    component: _4c156ead,
    name: "guide"
  }, {
    path: "/home",
    component: _913d2238,
    name: "home"
  }, {
    path: "/indexNew",
    component: _783be9a0,
    name: "indexNew"
  }, {
    path: "/indexOld",
    component: _03ae7437,
    name: "indexOld"
  }, {
    path: "/instructor",
    component: _8a0be4bc,
    name: "instructor"
  }, {
    path: "/logout",
    component: _761b35cc,
    name: "logout"
  }, {
    path: "/streaming",
    component: _3cdeecda,
    name: "streaming"
  }, {
    path: "/wishlist",
    component: _6621e34a,
    name: "wishlist"
  }, {
    path: "/collection/elearning",
    component: _42e3f697,
    name: "collection-elearning"
  }, {
    path: "/collection/streaming",
    component: _6c3c45b6,
    name: "collection-streaming"
  }, {
    path: "/dashboard/profile",
    component: _33adc95f,
    name: "dashboard-profile"
  }, {
    path: "/dashboard/transaction",
    component: _2bfc6ed4,
    name: "dashboard-transaction"
  }, {
    path: "/elearning/special",
    component: _72c6c29e,
    name: "elearning-special"
  }, {
    path: "/home/indexOld",
    component: _1a04455a,
    name: "home-indexOld"
  }, {
    path: "/site/aboutus",
    component: _e0b92736,
    name: "site-aboutus"
  }, {
    path: "/site/contactus",
    component: _4b31e238,
    name: "site-contactus"
  }, {
    path: "/site/faq",
    component: _87ac2fe0,
    name: "site-faq"
  }, {
    path: "/site/privacy",
    component: _706e7682,
    name: "site-privacy"
  }, {
    path: "/streaming/indexOld",
    component: _1adfdf78,
    name: "streaming-indexOld"
  }, {
    path: "/streaming/indexOldNew",
    component: _6ac25228,
    name: "streaming-indexOldNew"
  }, {
    path: "/streaming/special",
    component: _52acfa86,
    name: "streaming-special"
  }, {
    path: "/collection/elearning/idOld",
    component: _2fe5205e,
    name: "collection-elearning-idOld"
  }, {
    path: "/collection/streaming/idOld",
    component: _1165bef0,
    name: "collection-streaming-idOld"
  }, {
    path: "/collection/streaming/idOldNew",
    component: _0f1098c0,
    name: "collection-streaming-idOldNew"
  }, {
    path: "/collection/streaming/idOldNewNew",
    component: _c7aca620,
    name: "collection-streaming-idOldNewNew"
  }, {
    path: "/collection/streaming/indexOldNew",
    component: _8ff0a662,
    name: "collection-streaming-indexOldNew"
  }, {
    path: "/collection/elearning/:id",
    component: _775cb2bf,
    name: "collection-elearning-id"
  }, {
    path: "/collection/elearning/:idNew",
    component: _67a242de,
    name: "collection-elearning-idNew"
  }, {
    path: "/collection/streaming/:id",
    component: _266c3d9e,
    name: "collection-streaming-id"
  }, {
    path: "/elearning/:slug",
    component: _92fccda8,
    name: "elearning-slug"
  }, {
    path: "/elearning/:slugNew",
    component: _196c75f8,
    name: "elearning-slugNew"
  }, {
    path: "/elearning/:slugNewNew",
    component: _356fea2c,
    name: "elearning-slugNewNew"
  }, {
    path: "/elearning/:slugOld",
    component: _33162e0b,
    name: "elearning-slugOld"
  }, {
    path: "/form/:id?",
    component: _29b53d31,
    name: "form-id"
  }, {
    path: "/payment/:slug?",
    component: _5467726f,
    name: "payment-slug"
  }, {
    path: "/reset/:slug?",
    component: _1b2623f8,
    name: "reset-slug"
  }, {
    path: "/streaming/:slug",
    component: _404c2f6a,
    name: "streaming-slug"
  }, {
    path: "/streaming/:slugNewNew",
    component: _70f2260b,
    name: "streaming-slugNewNew"
  }, {
    path: "/streaming/:slugOld",
    component: _e294d2e8,
    name: "streaming-slugOld"
  }, {
    path: "/streaming/:slugOldNew",
    component: _853768b8,
    name: "streaming-slugOldNew"
  }, {
    path: "/verification/:slug?",
    component: _63f909d8,
    name: "verification-slug"
  }, {
    path: "/",
    component: _061ee280,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
