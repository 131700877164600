const state = () => ({
  cart: 0,
  notif: []
})

const mutations = {
  setCart (state, cart) {
    state.cart = cart
  },
  setNotif (state, notif) {
    state.notif = notif
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}