import qs from "qs"

const Cookie = process.client ? require('js-cookie') : undefined

export default function ({ $axios, store, redirect, app, error: nuxtError }) {

  $axios.defaults.paramsSerializer = function(params) {
    return qs.stringify(params)
  }

  $axios.onRequest(config => {
    config.headers.common['Authorization'] = process.env.API_SECRET
    config.headers.common['token'] = `${store.state.member.auth.token}`
    config.headers.common['x-api-key'] = process.env.API_AUTH // AUTH POS
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      redirect('/400')
    }

    if (code === 401) {
      Cookie.remove('token')
      Cookie.remove('user')
      Cookie.remove('profile')
      Cookie.remove('fcm')
      Cookie.remove('notif')

      redirect('/home')
    }

    if (code === 500) {
      redirect('/500')
    }

    nuxtError({
      statusCode: error.response.status,
      message: error.message,
    })
    return Promise.resolve(false)
  })
}

