import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyARi7c2ggOuXNv-PjI0YWWMx_nswe3FBNU",
  authDomain: "e-learning-bidan-kita.firebaseapp.com",
  projectId: "e-learning-bidan-kita",
  storageBucket: "e-learning-bidan-kita.appspot.com",
  messagingSenderId: "67168052814",
  appId: "1:67168052814:web:43a7bb3ad2dca8b169451f"
}

if (!firebase.apps.length) { 
  firebase.initializeApp(config)
}

export default (ctx, inject) => {
  inject('firebase', firebase)
}