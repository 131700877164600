import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyAGaaD8mpJVZiiF3eXok0WkZjINHmVEjyc',
		libraries: 'places',
		region: 'ID',
		language: 'id',
	},
	installComponents: true
})

export default VueGoogleMaps